import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class UsuarioService extends FetchService {

    listarFiltros() {
        return {
            todos: null,
            nome: null,
            telefone: null,
            email: null,
            status: null,
            ordenarPor: 0,
            ordem: 0,
            pagina: 1,
            itensPagina: 10,
        };
    }

    async listarCampos() {
        return [
            {
                descricao: "Reset",
                valor: "mdi-lock-open-outline",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "botao",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Nome",
                valor: "nome",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Telefone",
                valor: "telefone",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "E-mail",
                valor: "email",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Status",
                valor: "statusFormatado",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarUsuarios(filtro) {
        const route = `${vue.$globals.endpoint}usuario/listarUsuarios`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async listarUsuariosAtivos() {
        const route = `${vue.$globals.endpoint}usuario?listaCompleta=false`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async exportarUsuariosExcel(filtro) {
        const route = `${vue.$globals.endpoint}usuario/exportarUsuariosExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}usuario`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}usuario`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async efetuarLogin(usuario, senha) {
        const bodyRequest = {
            usuario: usuario,
            senha: senha
        };
        const route = `${vue.$globals.endpoint}usuario/login`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            bodyRequest, 
            false, 
            route,
            false
        );
    }

    async alterarSenhaUsuario(id, novaSenha, primeiroAcesso = true, validar = true) {
        const bodyRequest = {
            usuarioId: id,
            senha: novaSenha,
            primeiroAcesso: primeiroAcesso
        };
        const route = `${vue.$globals.endpoint}usuario/alterarSenhaUsuario`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            bodyRequest, 
            false, 
            route,
            validar
        );
    }

    async efetuarLoginApp(usuario, senha) {
        const bodyRequest = {
            usuario: usuario,
            senha: senha
        };
        const route = `${vue.$globals.endpoint}usuario/loginApp`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            bodyRequest, 
            false, 
            route,
            false
        );
    }

    async consultarUsuarioPorEmail(email, caminho) {
        const route = `${vue.$globals.endpoint}usuario/consultarUsuarioPorEmail?email=${email}&caminho=${caminho}`;
        return await this.fetchResponse(
            "GET",
            vue.$globals.headerPadrao,
            null,
            false,
            route,
            false
        );
    }

    async getById(id) {
        const route = `${vue.$globals.endpoint}usuario/GetLoginById?id=${id}`;
        return await this.fetchResponse(
            "GET",
            vue.$globals.headerPadrao,
            null,
            false,
            route,
            false
        );
    }
}

export default UsuarioService;