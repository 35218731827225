<template>
  <div>
    <Loading v-if="loading" />
    <div class="login-bg">
      <div class="login-card">
        <img
          src="@/assets/logo_dourado.png"
          alt="Fabio Merli"
          class="mt-6 mb-8"
        />
        <v-form v-model="valido" ref="formulario" class="login-form">
          <v-text-field
            ref="refEmail"
            label="E-mail"
            append-icon="person"
            type="email"
            v-on:keyup.enter="entrar"
            v-model="usuario"
            :rules="regrasUsuario"
            dense
            color="white"
            solo
          />

          <v-text-field
            id="password"
            label="Password"
            :type="showSenha ? 'text' : 'password'"
            v-on:keyup.enter="entrar"
            v-model="senha"
            :rules="regrasSenha"
            :append-icon="showSenha ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showSenha = !showSenha"
            color="white"
            solo
            class="pb-0"
            dense
          />
        </v-form>
        <div class="incorreto">
          <div class="text-center">
            <v-alert
              :value="alert"
              color="red"
              transition="scale-transition"
              text
            >
              <v-icon color="red" left>mdi-alert</v-icon>
              {{ mensagem }}
            </v-alert>
          </div>
        </div>
        <div class="login-action">
          <v-btn
            width="100%"
            color="button"
            v-on:click="entrar"
            class="mb-4 mt-2"
            >Access</v-btn
          >
        </div>
        <div class="esqueceu-a-senha">
          <span class="white--text"
            >Forgot password?
            <a class="white--text">Click here to reset</a></span
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{
            routeId ? "Reset Password" : "First Access"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="valido" ref="formularioAlteracao" class="pt-4">
            <v-row class="pt-2 pb-2">
              <v-col cols="12" class="pa-0 pa-sm-1">
                <v-text-field
                  outlined
                  dense
                  id="password"
                  label="New password"
                  prepend-icon="lock"
                  :type="showNovaSenha ? 'text' : 'password'"
                  v-on:keyup.enter="alterarSenha"
                  v-model="novaSenha"
                  :rules="[(v) => !!v || 'New password is required field']"
                  :append-icon="showNovaSenha ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNovaSenha = !showNovaSenha"
                />
              </v-col>
              <v-col cols="12" class="pa-0 pa-sm-1">
                <v-text-field
                  outlined
                  dense
                  id="password"
                  label="Confirm new password"
                  prepend-icon="lock"
                  v-on:keyup.enter="alterarSenha"
                  v-model="novaSenhaConfirmacao"
                  :rules="[(v) => !!v || 'Confirm new password is required field']"
                  :type="showConfirmarSenha ? 'text' : 'password'"
                  :append-icon="showConfirmarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmarSenha = !showConfirmarSenha"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div class="pr-8 pl-8 text-center">
          <v-alert
            :value="alertConfirmacao"
            color="red"
            transition="scale-transition"
            text
          >
            <v-icon color="red" left>mdi-alert</v-icon>
            {{ mensagemAlteracao }}
          </v-alert>
        </div>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            @click="modal = false"
            text
            right
            class="text-caption"
          >
            <v-icon class="text-caption">mdi-close</v-icon> Cancel
          </v-btn>
          <v-btn color="blue" @click="alterarSenha" text class="text-caption">
            <v-icon class="text-caption">mdi-content-save-outline</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MensagemErro />
    <ModalSucesso />
  </div>
</template>

<script>
import MensagemErro from "../../components/mensagem-erro.vue";
import ModalSucesso from "../../components/modal-sucesso.vue";
import Loading from "../../components/loading.vue";
import UsuarioService from "../../service/usuario-service.js";
const usuarioService = new UsuarioService();

export default {
  components: {
    Loading,
    MensagemErro,
    ModalSucesso,
  },
  data() {
    return {
      loading: false,
      valido: true,
      validarLogin: false,
      alert: null,
      showSenha: false,
      usuario: null,
      senha: null,
      alertConfirmacao: false,
    };
  },
  methods: {
    async entrar() {
      this.validarLogin = true;
      setTimeout(async () => {
        if (this.$refs.formulario.validate()) {
          this.loading = true;
          let result = await usuarioService.efetuarLogin(
            this.usuario,
            this.senha
          );
          if (result?.statusCode === 200) {
            if (result?.data.primeiroAcesso) {
              this.loading = false;
              this.modal = true;
              this.objLogin = result?.data;
            } else {
              this.loading = false;
              this.setLogin(result?.data);
            }
          } else {
            this.loading = false;
            if (result?.statusCode == 401) {
              this.mensagem = result?.data.mensagem;
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 3000);
            } else {
              this.mensagemAlerta = result?.data.mensagem;
              this.alertaErro = true;
            }
          }
        }
      }, 100);
    },
    async alterarSenha() {
      if (this.$refs.formularioAlteracao.validate()) {
        if (
          this.novaSenha === this.novaSenhaConfirmacao &&
          this.novaSenha !== this.objLogin.senha
        ) {
          this.loading = true;
          let result = await usuarioService.alterarSenhaUsuario(
            this.objLogin.id,
            this.novaSenha,
            false,
            false
          );
          if (result?.statusCode === 200) {
            this.loading = false;
            this.setLogin(this.objLogin);
          } else {
            this.loading = false;
            this.mensagemAlerta = result?.data.mensagem;
            this.alertaErro = true;
          }
        } else {
          this.alertConfirmacao = true;
          this.mensagemAlteracao = "The entered passwords do not match";
          setTimeout(() => {
            this.alertConfirmacao = false;
          }, 3000);
        }
      }
    },
    setLogin(objLogin) {
      const rotaNavegacao = localStorage.getItem("rotaNavegacao");

      localStorage.setItem("login", JSON.stringify(objLogin));
      if (rotaNavegacao) {
        localStorage.removeItem("rotaNavegacao");
        this.$router.push(rotaNavegacao).catch((failure) => {
          localStorage.setItem("erro", failure);
        });
      } else
        this.$router.push("/admin/home").catch((failure) => {
          localStorage.setItem("erro", failure);
        });
    },
    async obterUsuarioReset() {
      this.loading = true;
      let result = await usuarioService.getById(this.routeId);
      if (result?.statusCode === 200) {
        this.loading = false;
        this.objLogin = result?.data;
        if (this.objLogin.resetSolicitado) this.modal = true;
      } else {
        this.loading = false;
      }
    },
  },
  mounted: function () {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    const routeId = this.$route.query.id;
    if (routeId) {
      this.routeId = routeId;
      this.obterUsuarioReset();
    }
    setTimeout(() => {
      this.$refs.refEmail.focus();
    }, 250);
  },
  computed: {
    regrasUsuario() {
      const rules = [];
      if (this.validarLogin) {
        const rule = (v) => !!v || "E-mail is a required field";
        rules.push(rule);
      }
      return rules;
    },
    regrasSenha() {
      const rules = [];
      if (this.validarLogin) {
        const rule = (v) => !!v || "Password is a required field";
        rules.push(rule);
      }
      return rules;
    },
  },
};
</script>

<style scoped>
.login-bg {
  height: 100vh;
  width: 100vw;
  margin: 0;
  background-image: url("../../assets/login_bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 400px;
  height: 540px;
  padding: 12px 48px;
  background-color: black;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.75em;
}

.login-card img {
  height: 150px;
}

.login-form {
  width: 100%;
}

.login-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.incorreto {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.esqueceu-a-senha a {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .login-card {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    justify-content: center;
  }

  .esqueceu-a-senha {
    font-size: 0.9rem;
  }
}
</style>